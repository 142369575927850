var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.sessions.length === 0)?_c('ui-card',[_c('p',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(`${_vm.$t("placeholders.no_sessions_registered_on_course")}`)+" ")])]):_c('responsive-ui-list',{attrs:{"heading":_vm.heading,"headings":[
    _vm.$t('fields.date'),
    _vm.$tc('models.group', 1),
    _vm.$t('fields.location'),
    _vm.$t('language.language'),
    _vm.$tc('misc.registered', 2),
    _vm.$t('actions.complete'),
  ],"sort":[
    { prop: 'startDateTime', type: false },
    undefined,
    { prop: 'location', type: false },
    { prop: 'language', type: false },
    { prop: 'progress', prop2: 'progressPercentage', type: 'level2' },
  ],"items":_vm.sessions},scopedSlots:_vm._u([(_vm.$slots.actions)?{key:"actions",fn:function(){return [_vm._t("actions")]},proxy:true}:null,{key:"default",fn:function(props){return [[(
          _vm.$d(new Date(props.item.startDateTime), 'fullShort') === _vm.$d(new Date(props.item.endDateTime), 'fullShort')
        )?_c('ui-list-data',{staticClass:"list_data",attrs:{"text":`
                  ${_vm.$d(new Date(props.item.startDateTime), 'fullShort', _vm.$i18n.locale === 'no' ? 'no' : 'en')}
              `}}):_vm._e(),(
          _vm.$d(new Date(props.item.startDateTime), 'fullShort') != _vm.$d(new Date(props.item.endDateTime), 'fullShort')
        )?_c('ui-list-data',{staticClass:"list_data",attrs:{"text":`
                  ${_vm.$d(new Date(props.item.startDateTime), 'fullShort', _vm.$i18n.locale === 'no' ? 'no' : 'en')} 
                  - 
                  ${_vm.$d(new Date(props.item.endDateTime), 'fullShort', _vm.$i18n.locale === 'no' ? 'no' : 'en')}
              `}}):_vm._e()],_c('ui-list-data',{staticClass:"list_data",attrs:{"text":`
              ${_vm.$d(new Date(props.item.startDateTime), 'time', _vm.$i18n.locale === 'no' ? 'no' : 'en')}
              -
              ${_vm.$d(new Date(props.item.endDateTime), 'time', _vm.$i18n.locale === 'no' ? 'no' : 'en')}`}}),_c('ui-list-data',{staticClass:"list_data",attrs:{"type":"tag","text":props.item.location}}),_c('ui-list-data',{staticClass:"list_data",attrs:{"small":"","type":"tag","text":props.item.language === 'en'
          ? _vm.$t('language.english', 'en').toString()
          : _vm.$t('language.norwegian', 'no').toString()}}),(_vm.sessions)?[(typeof _vm.getPercentage(props.item) === 'number')?_c('ui-list-data-progress',{staticClass:"list_data",attrs:{"percentage":_vm.getPercentage(props.item),"progressDetails":props.item.progress}}):_vm._e(),(_vm.getPercentage(props.item) === false)?_c('ui-list-data',{staticClass:"list_data",attrs:{"text":_vm.$t('labels.course.no_users_assigned')}}):_vm._e()]:_vm._e(),_c('ui-list-data',{staticClass:"list_data",attrs:{"small":""}},[_c('auth-checker',{attrs:{"accessRoles":['SuperAdmin', 'InstructorAdmin', 'Instructor']}},[_c('ui-button',{attrs:{"type":"green","size":"small","uppercase":""},on:{"click":function($event){return _vm.$router.push({
              name: 'courseevent-session-view',
              params: { id: props.item.courseEvent, sessionId: props.item.id },
            })}}},[_vm._v(" "+_vm._s(_vm.$t("actions.show_content"))+" ")])],1),_c('auth-checker',{attrs:{"accessRoles":['SuperAdmin', 'InstructorAdmin', 'Instructor']}},[(props.item.active === true)?_c('ui-button',{staticClass:"ml-6",attrs:{"type":"grey","size":"small","uppercase":""},on:{"click":function($event){return _vm.updateSession(props.item.id, false)}}},[_c('i',{staticClass:"el-icon-check"}),_vm._v(" "+_vm._s(_vm.$t("actions.complete"))+" ")]):_c('ui-button',{staticClass:"ml-6",attrs:{"type":"red","size":"small","uppercase":""},on:{"click":function($event){return _vm.updateSession(props.item.id, true)}}},[_c('i',{staticClass:"el-icon-close"}),_vm._v(" "+_vm._s(_vm.$t("actions.undo"))+" ")])],1)],1)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }