<template>
    <div v-loading="loading">
      <auth-checker :accessRoles="['SuperAdmin', 'InstructorAdmin', 'Instructor']">
        <div v-if="courseEvent">
            <ui-card class="ui_card">
                <ui-header class="ui_header" :heading="courseEvent.title"></ui-header>
                <RichTextField 
                  class="mb-3"
                  v-model="courseEvent.description"
                  :hideBorder="true"
                  :showEditor="false">
                </RichTextField>
                <ui-header class="ui_header" :heading="$t('fields.internalDescription')"></ui-header>
                <RichTextField 
                  class="mb-3"
                  v-model="courseEvent.internalDescription"
                  :hideBorder="true"
                  :showEditor="false">
                </RichTextField>
            </ui-card>
            <ui-card class="ui_card">
                <ui-form-field
                  id="linkFormField"
                  :label="$t('links.registration_link')">

                    <el-input
                      id="eventLink"
                      name="eventLink"
                      ref="eventLink"
                      :value="`${registrationLinkBase}${courseEvent.slug}`">
                        <i @click="copy" slot="suffix" class="link el-input__icon el-icon-copy-document"></i>
                    </el-input>
                    <p class="text-green-700 italic text-sm" v-if="copied">{{ capitalized($t('feedback.copied')) }}</p>
                </ui-form-field>
            </ui-card>
            <auth-checker :accessRoles="['SuperAdmin', 'InstructorAdmin', 'Instructor']">
                <session-list
                    :heading="$tc('models.group', 2)"
                    :sessions="courseEvent.sessions"
                />
            </auth-checker>

        </div>
        <div class="flex flex-row justify-end">
            <ui-link class="navigation_button" type="text" :route="{ name: 'courseevent-index' }">
            {{
                `${$t('actions.return')}`
            }}
            </ui-link>
        </div>
      </auth-checker>
    </div>
</template>

<script>
import UiHeader from '@/components/ui/UiHeader';
import UiLink from '@/components/ui/UiLink';
import UiCard from '@/components/ui/UiCard';
import UiFormField from '@/components/ui/UiFormField';
import SessionList from '@/app/courseevents/components/CourseEventSessionList';
import AuthChecker from '../../auth/components/AuthChecker';
import RichTextField from "@/components/form/RichTextField";
import {getCourseEvent} from '../api';
import { capitalized } from "@/extras";

export default {
  data() {
    return {
      courseEvent: null,
      loading: false,
      copied: false,
      registrationLinkBase: process.env.VUE_APP_COURSEEVENT_URL
    };
  },

  components: {
    UiHeader,
    UiLink,
    UiCard,
    UiFormField,
    SessionList,
    RichTextField,
    AuthChecker
  },

  methods: {
    capitalized,
    async getCourseEvent() {
      this.courseEvent = await getCourseEvent(this.$route.params.id);
    },

    copy() {
      navigator.clipboard.writeText(this.$refs.eventLink.value);

      const message = capitalized(this.$t('feedback.copied').toString());
      this.$message.success(message);
      this.copied = true;
    },
  },

  async created() {
    this.loading = true;
    await this.getCourseEvent();

    // Create uppercase locations to ensure correct sorting
    this.courseEvent.sessions.forEach((session) => {
      session.location = session.location ? session.location[0].toUpperCase() + session.location.slice(1) : session.location;
    });
    this.loading = false;
  },

  mounted() {
    this.$store.commit('changeBreadcrumbs', [
      {name: "models.event", link: "/app/courseevent/" },
      {name: "models.group_plural", link: "/app/courseevent/:id/view"}
    ]);
  },
}
</script>

<style scoped>
/** Fixing wrong margin on p, and showing lists */
:deep(.ProseMirror) p {
  margin-bottom: 0;
}
:deep(.ProseMirror) ol {
  list-style-type: decimal;
  margin-left: 20px;
}
:deep(.ProseMirror) ul {
  list-style-type: disc;
  margin-left: 20px;
}

.link:hover {
  cursor: pointer;
}
#linkFormField {
  margin-bottom: 0 !important;
}
.ui_card {
    margin-bottom: 10px;
}
.navigation_button {
    margin-top: 5px;
    margin-left: 5px;
    background-color: white;
    border: solid 1px black;
    color: black;
}
.ui_header {
  margin-bottom: 0;
}
</style>
